import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { firebaseReducer } from 'react-redux-firebase';
import { getFirebase, getFirestore } from 'react-redux-firebase';
import thunk from 'redux-thunk';
import { persistReducer } from "redux-persist";
import globalMiddleware from "./middlewares/globalMiddleware";
import localStorage from 'redux-persist/lib/storage';
import fbConfig from '../firebase/fbconfig';
import { firestoreReducer } from 'redux-firestore';

const persistConfig = {
    key: "firebaseState",
    storage : localStorage
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState = {};

const enhancers = composeEnhancers(
    applyMiddleware(
      globalMiddleware,
      thunk.withExtraArgument({ getFirebase, getFirestore })
    ),
);

// Initialize firebase instance
firebase.initializeApp(fbConfig);

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store with reducers and initial state
const store = createStore(persistedReducer, initialState, enhancers)

export default store;