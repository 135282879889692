import React, { Suspense }   from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider, createFirestoreInstance} from 'react-redux-firebase';
import firebase from "firebase/app";
import {
  BrowserRouter as Router
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
const App = React.lazy(() => import('./App'));

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, 
  attachAuthIsReady: true,
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <HelmetProvider>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <Suspense fallback={<div>Loading...</div>}>
              <App />
            </Suspense>
          </ReactReduxFirebaseProvider>
        </HelmetProvider>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
