const fbConfig = {
    apiKey: "AIzaSyCl2KtQWUlccgLAmvxFfWyjI5xnzkTcdGo",
    authDomain: "katalys-my-scan-bdd.firebaseapp.com",
    databaseURL: "https://katalys-my-scan-bdd.firebaseio.com",
    projectId: "katalys-my-scan-bdd",
    storageBucket: "katalys-my-scan-bdd.appspot.com",
    messagingSenderId: "662454511942",
    appId: "1:662454511942:web:32b3e018c14ac3f518dd47",
    measurementId: "G-7ML7N0RT93"
};

export default fbConfig;